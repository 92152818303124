import React from "react"
import { graphql } from "gatsby"

import {Row,Card,Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import SecondLayout from "../components/secondlayout"
import Accordion from 'react-bootstrap/Accordion'


const SupportTemplate = ({data, pageContext: {lang, currentPath}}) => console.log('') || (
    
  <SecondLayout id="singlepage" wpLang={lang} currentPath={currentPath} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
<Row className="faq-page">
<div className="entry-content">
<h1 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.frequently_asked_questions}} />
<div className="faq-content">
<Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="1">
                          { data.wordpressPage.acf.faq_title }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body><p>{ data.wordpressPage.acf.faq_content }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
      
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="2">
                          { data.wordpressPage.acf.faq_title_1 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_1 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
      
                          <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="3">
                          { data.wordpressPage.acf.faq_title_2 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_2 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
      
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="4">
                          { data.wordpressPage.acf.faq_title_3 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_3 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>


                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="5">
                          { data.wordpressPage.acf.faq_title_4 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_4 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="6">
                          { data.wordpressPage.acf.faq_title_5 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_5 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="7">
                          { data.wordpressPage.acf.faq_title_6 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_6 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="8">
                          { data.wordpressPage.acf.faq_title_7 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="8">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_7 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="9">
                          { data.wordpressPage.acf.faq_title_8 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="9">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_8 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="10">
                          { data.wordpressPage.acf.faq_title_9 }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="10">
                        <Card.Body><p>{ data.wordpressPage.acf.faq_content_9 }</p></Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                    </div>
                </div>
        </Row>
  </SecondLayout>
)
export const query = graphql`
query($lang: String) {
  wordpressPage(
    slug: {eq: "home"}
    wpml_current_locale: {eq: $lang}
  ) {
  acf {
    faq_content
    faq_content_1
    didnt_find_an_answer_go_to_support_page
    faq_title_3
    faq_content_3
    faq_content_9
    faq_content_2
    faq_content_4
    faq_content_5
    faq_content_6
    faq_content_7
    faq_content_8
    faq_title
    faq_title_1
    faq_title_2
    faq_title_4
    faq_title_5
    faq_title_6
    faq_title_7
    faq_title_9
    faq_title_8
    frequently_asked_questions
    grow_tile
    home_desc
    home_title
    how_1_desc
    how_1_title
    how_2_desc
    how_3_desc
    how_2_title
    how_3_title
    how_do_grow
    how_it_works
    how_to_desc
    piority_desc
    piority_title
    sign_up
    start_growing
    start_now
    time_to_start
    time_to_start_1_desc
    time_to_start_1_title
    time_to_start_2_desc
    time_to_start_2_title
    time_to_start_3_desc
    time_to_start_3_title
  }
    title
    yoast_meta {
      name
      content
      property
    }
    yoast_title
      wpml_current_locale
  }
  Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }


  SendAs: file(relativePath: { eq: "mac2.png" }) {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  GetAs: file(relativePath: { eq: "card2.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  play: file(relativePath: { eq: "play-button.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  right: file(relativePath: { eq: "right.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  Hand: file(relativePath: { eq: "hand.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  Custom_one: file(relativePath: { eq: "1.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  Custom_two: file(relativePath: { eq: "2.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  Custom_three: file(relativePath: { eq: "3.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }    
  Custom_four: file(relativePath: { eq: "4.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  arrow_right: file(relativePath: { eq: "right.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }


}
`
export default SupportTemplate